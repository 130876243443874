import { graphql } from 'gatsby';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import Helmet from 'react-helmet';
import { ToastContainer } from 'react-toastify';
import { Box } from 'rebass';
import Banner from '../components/Banner';
import BookingArtist from '../components/BookingArtist';
import BookingArtistsNav from '../components/BookingArtistsNav';
import BookingCover from '../components/BookingCover';
import EventRow from '../components/EventRow';
import Footer from '../components/Footer';
import Header from '../components/Header';
import Toggle from '../components/Toggle';
import '../styles/plyr.css';

class Booking extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      activeItem: 0,
    };
  }

  setActiveItem = (index) => {
    this.setState({
      activeItem: index,
    });
  };

  render() {
    const {
      data: { artists: artistsData, events: eventsData },
    } = this.props;

    const { activeItem } = this.state;

    const artists = artistsData.nodes;

    const events = eventsData.nodes;

    return (
      <div>
        <Helmet>
          <title>Booking</title>
        </Helmet>
        <ToastContainer />
        <Banner />
        <Header
          activePageName="BOOKING"
          rightText={
            activeItem === 0 ? 'DISCOVER OUR ROSTER' : 'NEXT & PAST GIGS'
          }
        />
        <BookingCover />
        <Toggle
          activeItem={activeItem}
          firstItem="ROSTER"
          secondItem="GIGS"
          setActiveItem={this.setActiveItem}
        />
        <div id="content">
          {activeItem === 0 && <BookingArtistsNav artists={artists} />}
          {activeItem === 0 && (
            <Box pb={['0', '0', '25px']}>
              {artists.map((artist) => (
                <BookingArtist
                  artist={artist}
                  isManagementPage={false}
                  key={artist.id}
                />
              ))}
            </Box>
          )}

          {activeItem === 1 && (
            <Box mb={['0', '0', '-15px']}>
              {events.map((event) => (
                <EventRow event={event} key={event.id} />
              ))}
            </Box>
          )}
        </div>
        <Footer activePageName="BOOKING" />
      </div>
    );
  }
}

Booking.propTypes = {
  data: PropTypes.shape({
    artists: PropTypes.shape({
      nodes: PropTypes.arrayOf(
        PropTypes.shape({
          biography: PropTypes.string.isRequired,
          bookingAgents: PropTypes.arrayOf(
            PropTypes.shape({
              email: PropTypes.string.isRequired,
              title: PropTypes.string.isRequired,
            }).isRequired,
          ).isRequired,
          bookingPageAttributes: PropTypes.arrayOf(
            PropTypes.shape({
              title: PropTypes.string.isRequired,
              value: PropTypes.string.isRequired,
            }).isRequired,
          ).isRequired,
          id: PropTypes.string.isRequired,
          musicWidget: PropTypes.shape({
            artistId: PropTypes.string.isRequired,
            type: PropTypes.string.isRequired,
          }).isRequired,
          pressKitUrl: PropTypes.string,
          socialLinks: PropTypes.arrayOf(
            PropTypes.shape({
              name: PropTypes.string.isRequired,
              url: PropTypes.string.isRequired,
            }).isRequired,
          ).isRequired,
          title: PropTypes.string.isRequired,
          video: PropTypes.string,
        }).isRequired,
      ).isRequired,
    }).isRequired,
    events: PropTypes.shape({
      nodes: PropTypes.arrayOf(
        PropTypes.shape({
          artists: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
          date: PropTypes.string.isRequired,
          id: PropTypes.string.isRequired,
          place: PropTypes.shape({
            city: PropTypes.string.isRequired,
            name: PropTypes.string.isRequired,
          }).isRequired,
        }).isRequired,
      ).isRequired,
    }),
  }).isRequired,
};

export const query = graphql`
  {
    artists: allArtistsJson(
      sort: { fields: [title] }
      filter: { showOnBookingPage: { eq: true } }
    ) {
      nodes {
        id
        title
        image {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        biography
        bookingAgents {
          email
          title
        }
        managementAgents {
          email
          title
        }
        bookingPageAttributes {
          title
          value
        }
        managementPageAttributes {
          title
          value
        }
        pressKitUrl
        musicWidget {
          type
          artistId
        }
        socialLinks {
          name
          url
        }
        youtubeIframeUrl
      }
    }

    events: allEventsJson(
      filter: { isGig: { eq: true } }
      sort: { fields: [date], order: DESC }
    ) {
      nodes {
        id
        artists
        place {
          name
          city
        }
        date
        eventUrl
        ticketsUrl
      }
    }
  }
`;

export default Booking;
